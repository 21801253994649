// Peinture
export const peinture = `Je fais de la peinture, car c’est ce qui me fait voir l’autre côté. L’autre côté de la porte ou le soleil brille plus fort, les couleurs sont plus intenses, les fruits ont meilleurs goûts. Je suis diplômé d’un Master de gestion des risques financiers, j’ai donc pris le risque immense de goûter à ce côté-là en espérant pouvoir y passer le reste de ma vie.
    
La peinture non comme échappatoire, mais comme un monde utopique parallèle ou les seules règles sont fixées par toi-même. J’ai commencé la peinture par quête de liberté. Liberté qui émane non des multiples possibilités qui se présentent à toi, mais de l’expérimentation de toi-même face à un vide, un tout qui se présente et qui n’attend rien. 
    
Pour une fois sentir ce souffle de liberté qui te pousse à un investissement sans faille dans un monde sans contrainte, sans limites. Les questions n’apparaissent plus, les essais se font nombreux. Tout est éclairé comme sentir l’eau salée durcir sur sa peau, un frisson d’un timbre de voix inattendu, marcher pied nu. La construction se fait sans bruit, lentement, jusqu’à toucher notre âme du doigt.`;

// Abstrait
export const abstrait = `L’art abstrait est une symphonie sans partition ou les sons se succèdent et s’entremêlent sans qu’on ne puisse l’expliquer.

J’aime l’abstrait, il ouvre les portes de notre inconscient de notre âme en poussant exclusivement notre cerveau à se mettre de côté. Le figuratif quant à lui te bride dans ton expérimentation de l’œuvre en te traçant des chemins de pensée préconçus liés à notre cadre intellectuel. Compte tenu de cela, l’art abstrait est un raccourci à l’âme, une discussion privée à notre intérieur, un soulagement, un court-circuit de notre cerveau. L’Abstrait nous expérimente à sa guise et nous empreinte de notre spontanéité. J’aime l’art abstrait dans le sens qu’on lui donne, il éclaire les endroits sombres et révèle les personnalités de chacun. L’art abstrait qui fait débat, en figure d’opposition, sa dualité accompagnée du silence ou d’un enchevêtrement de bruits. Parmi le bruit des conversations et des débats, la toile règne en maître et s’étale de toute sa puissance, formant comme une aura autour d’elle.`;

// Matière
export const matiere = `Par définition philosophique, la matière est la substance qui constitue le monde sensible, les corps. Une substance que l'on peut connaître par les sens, qu'elle prenne ou non une forme déterminée. Je ne parle à travers mes œuvres pas au spectateur, mais à ses sens, je souhaite qu’il soit épris de l’œuvre, qu’il puisse l’expérimenter, tisser un lien dont émane des sentiments et des émotions qui devra apprendre à gérer.

La primauté de la matière est au centre de mon art. Je suis un hypersensible. Je suis animé par mes sens et j’aime à les tester en découvrant des nouveaux univers de sensations. Dans ma construction, je me satisfais en jouant avec la matière, l’utilisant en sa faveur ou contre elle. Je parfais mes rendus, les effets, les assemblages de texture. J’ai usé des combinaisons s’accompagnant de montagnes russes émotionnelles. Le terme d’assemblage est brut, je parlerais plus dans mon cas d’une chorégraphie, d’une symphonie ou chaque note vient s’additionner aux autres pour construire un tout harmonieux qui éclate à la lueur du jour, qui naît.

J’ai la chance d’être né Taureau, dès le premier instant la terre mère m’a accueilli sous tous ses charmes. J’ai directement découvert la matière au stade d’excellence. Par conséquent, je suis en quête constante de cet état de matière. Je recherche activement les moments où tous mes sens sont comblés par l’univers en action. La matière est source de toutes les magies. Composer avec cette matière est pour moi une finalité. J’utilise exclusivement mes sens dans mon processus de création et je vis un état d’extase, une expérience unique, qui me transporte et m’entrechoque.

Chaque œuvre est chargée d’émotion, de sentiments comme un assaut aux spectateurs. Vous avez sous les yeux ma corde vibrante, mon cri qui s’échappe de la toile. Indiscipliné et guidé par mes pulsions, je cherche à la peinture active. Je donne le mouvement, je suis mes impulsions, je guette les effets, les rendus et je m’imprègne comme un buvard. 

Je matérialise cette nécessité de matière au stade d’excellence en suivant mon corps. Je rentre dans un état ou je ne sais rien, ou j’accueille et j’accepte tout ce qui vient, totalement libre des blocages de la pensée et je laisse la libre parole à mon corps. J’expérimente, je suis le sujet et l’acteur principal de mon œuvre.`;

// Lumière et couleur
export const lumiere = `Fervent admirateur de la recherche de Paul KLEE, SOULAGES, KLEIN ou encore ROHTKO. Je rejoins la pensée d’Yves KLEIN expliquant  que la monochromie est le moyen de « sentir l’âme sans l’expliquer, sans vocabulaire ». Il y a un intérêt dans l’individualisation de la couleur que j’ai cherché à intégrer dans mon œuvre, car chaque couleur à une parole et s’exprime pleinement et il est important d’entendre chacune de ces paroles. Je suis au service de l’expression de la couleur et je mets tout en œuvre pour que celle-ci puisse retentir.

En parallèle de cela, j’utilise en vecteur de communication de la couleur, le noir. Le noir par sa dualité s’avère être un des serviteurs les plus efficace de la couleur. De plus, on découvre un « noir outil de lumière » qui vient apporter une forme à la couleur. Comme le précisait Pierre SOULAGES, «  les couleurs n’existent pas en tant que telles » « une chose qui change une couleur, c’est la forme ».  

C’est dans ce sens que le noir apparaît comme ayant un rôle central dans la composition. Il sert l’œuvre, plus que ça, il l’a défini et l’a fait naître. Dans la tradition philosophique classique, il existait une opposition très marquée entre la lumière et la matière. C’est tout l’enjeu des œuvres que je propose, une cohabitation entre la matière représentée par la couleur et la lumière. Ces deux sujets ne peuvent cohabiter sans l’intervention du noir en tant que médiateur qui encercle et dessine des formes, il nous prive pour mieux nous faire apprécier. L’œuvre met au défi notre perception, nous intègre et ne nous invite pas à la réflexion, mais à la contemplation par le corps.`;

// Invitation
export const invitation = `Nous ne célébrons plus la matière alors que celle-ci est la naissance de chacun de nous. Nous avons évolué avec celle-ci à travers nos ressentis. Alors je vous propose de retrouver cette dynamique, contempler la matière qui s’offre à vous, en vous attardant sur vos émotions, vos sentiments. Percevez les détails, rentrer dans cette transe où vous pouvez connecter avec l’œuvre et avec vous-même. Saisissez et ressentez la matière qui vous envahit, le frisson, le mouvement qui parcourt votre corps. Laissez-vous cueillir, laissez-vous accompagner hors de votre réalité et goûter aux fruits les meilleurs. Comme le mentionnait Monsieur David SERVAN-SCHREIBER, « l’attention est de l’énergie pure », nourrissez l’œuvre d’attention et ressentez le circuit qui vous lie à la terre mère, a la matière.

L’œuvre vous invite à une expérimentation par le corps. Laissez-vous impactez et cherchez les émotions les plus profondes. Laissez-vous guider par vos intuitions, car seules les intuitions sont vraies et restent vraies, elles vous détachent du reste et constitue le seul rempart de la liberté.`;

export const painting = `I paint because it is what makes me see the other side. The other side of the door where the sun shines brighter, the colors are more intense, the fruits taste better. I have a Master's degree in financial risk management, so I took the huge risk of tasting that side hoping to spend the rest of my life there.

Painting not as an escape, but as a parallel utopian world where the only rules are set by yourself. I started painting in search of freedom. Freedom that emanates not from the multiple possibilities that present themselves to you, but from the experimentation of yourself in front of an emptiness, a whole that presents itself and expects nothing. For once, feel this breath of freedom that pushes you to an unfailing investment in a world without constraints, without limits. The questions no longer appear, the trials are numerous. Everything is enlightened like feeling the salt water harden on your skin, a shiver of an unexpected voice, walking barefoot. The construction is done without noise, slowly, until touching our soul with the finger.`