import React from 'react';
import './loading.styles.scss';

const Loading = () => {
    return(
        <div className="loadingContainer">
            <h1>KAMONN</h1>
        </div>
    );
}

export default Loading;